import comp from '../components/barrel';
import svg from '../js/svg';
import U from '../js/utils';

export default {
  application_uuid: null,
  genders: null,
  party1_name: null,
  party2_name: null,
  present: 'Present Relationship',
  party1: 'Party 1: Previous Marriage',
  party2: 'Party 2: Previous Marriage',
  other: 'Other Children',
  async prepare() {
    this.application_uuid = thsPage.vars.application_uuid;
    const vars = {};
    vars.application_uuid = this.application_uuid;
    this.recipe.methods.push(await O.mPOST('application/get-children-detail', vars));
    this.recipe.methods.push(await O.mPOST('communal/genders', vars));
  },
  async render() {
    const rec = this.recipe.methods;
    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    const ChildrenData = data[`application/get-children-detail`].data;
    this.genders = data[`communal/genders`].data;
    this.party1_name = ChildrenData.users[0].party1_name;
    this.party2_name = ChildrenData.users[0].party2_name || null;
    console.log('ChildrenData:', ChildrenData);

    // -------------------------------
    // No Children Section
    let noChildren = U.id(`noChildrenDiv`);
    let cb1 = await comp.inputCheckbox('noChildren', ChildrenData.children_yn, 'Children');
    noChildren.appendChild(cb1);
    U.id(`noChildren`).setAttribute('onchange', `thsSubTab.noChilren()`);
    if (!ChildrenData.children_yn) {
      U.hide('ChildSection');
    }
    // -------------------------------
    // Preset Marriage
    const presentData = ChildrenData.present;
    this.buildChildTable('present', presentData);
    // }
    // -------------------------------
    // Party 1 Marriage
    const p1Data = ChildrenData.party1;
    const p1name = U.id('party1Name');
    p1name.innerHTML = this.party1_name;
    this.buildChildTable('party1', p1Data);

    // -------------------------------
    // Party 2 Marriage
    if (ChildrenData.users[0].party2_uuid) {
      const p2Data = ChildrenData.party2;
      const p2name = U.id('party2Name');
      p2name.innerHTML = this.party2_name;
      this.buildChildTable('party2', p2Data);
    } else {
      U.hide('p2prev');
    }

    // -------------------------------
    // Other Children
    const otherData = ChildrenData.other;
    this.buildChildTable('other', otherData, 1);
  },
  buildChildTable(childSection, data, otherYN) {
    const table = U.id(`${childSection}`);
    table.innerHTML = '';

    // HEADER
    const thead = U.divAtt('thead');
    const headRow = thead.insertRow();
    const nameH = headRow.insertCell(0);
    nameH.innerHTML = 'Name';
    const sexH = headRow.insertCell(1);
    sexH.innerHTML = 'Gender';
    const dobH = headRow.insertCell(2);
    dobH.innerHTML = 'Date of birth';
    if (otherYN) {
      const wedlockH = headRow.insertCell(3);
      wedlockH.innerHTML = 'Born out of wedlock';
      const PredeceasedH = headRow.insertCell(4);
      PredeceasedH.innerHTML = 'Predeceased';
      const adoptedH = headRow.insertCell(5);
      adoptedH.innerHTML = 'Legally adopted';
      const removeH = headRow.insertCell(6);
      removeH.innerHTML = 'Remove';
    } else {
      const removeH = headRow.insertCell(3);
      removeH.innerHTML = 'Remove';
    }
    thead.appendChild(headRow);
    table.appendChild(thead);

    // BODY
    const tbody = U.divAtt('tbody');
    table.appendChild(tbody);
    let crossIcon = svg.cross().outerHTML;
    let tickIcon = svg.tick().outerHTML;
    if (data.length) {
      data.forEach(async (row) => {
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = `${row.f_name} ${row.s_name}`;
        const sex = childRow.insertCell(1);
        sex.innerHTML = (await U.getTextByUuid(this.genders, 'gender_uuid', row.gender, 'short_text')) || '';
        const dob = childRow.insertCell(2);
        dob.innerHTML = (await U.formatDate(row.dob)) || '';
        if (otherYN) {
          const wedlock = childRow.insertCell(3);
          wedlock.innerHTML = row.wedlock ? tickIcon : crossIcon;
          const Predeceased = childRow.insertCell(4);
          Predeceased.innerHTML = row.predeceased ? tickIcon : crossIcon;
          const adopted = childRow.insertCell(5);
          adopted.innerHTML = row.adopted ? tickIcon : crossIcon;
          const removeIcon = childRow.insertCell(6);
          const onclick = `thsSubTab.removeChild('${row.application_children_uuid}','${childSection}')`;
          const linkSVG = svg.trash(onclick);
          removeIcon.appendChild(linkSVG);
        } else {
          const removeIcon = childRow.insertCell(3);
          const onclick = `thsSubTab.removeChild('${row.application_children_uuid}','${childSection}')`;
          const linkSVG = svg.trash(onclick);
          removeIcon.appendChild(linkSVG);
        }
      });
    } else {
      //No children ---
      const childRow = tbody.insertRow();
      const noChild = childRow.insertCell(0);
      noChild.innerHTML = 'No children in this category.';
      noChild.colSpan = 4;
      if (otherYN) {
        noChild.colSpan = 7;
      }
    }
  },
  async buildChildForm() {
    const grid2 = U.divAtt('div', 'class', 'res-grid-cols-2');
    const title = U.divAtt('h6', 'class', 'text-primary');
    title.innerHTML = `Child Information`;
    let e_f_name = await comp.inputText(`newChild_f_name`, '', 'Full names ', 1);
    let e_s_name = await comp.inputText(`newChild_s_name`, '', 'Surname ', 1);
    let e_sex = await comp.select(`newChild_gender`, this.genders, 'gender_uuid', 'short_text', 'Gender', 1);
    let e_dob = await comp.inputDate(`newChild_dob`, '', 'Date of birth ', 1);

    grid2.appendChild(e_f_name);
    grid2.appendChild(e_s_name);
    grid2.appendChild(e_sex);
    grid2.appendChild(e_dob);
    return grid2;
  },
  async otherChildExtraInfoForm() {
    const grid2 = U.divAtt('div', 'class', 'res-grid-cols-2 mb-8');
    const cbWedlock = await comp.inputCheckbox(`newChild_wedlock`, false, 'Born out of wedlock');
    const cbPredeseased = await comp.inputCheckbox(`newChild_predeceased`, false, 'Predeceased');
    const cbAdopted = await comp.inputCheckbox(`newChild_adopted`, false, 'Legally adopted');
    grid2.appendChild(cbWedlock);
    grid2.appendChild(cbPredeseased);
    grid2.appendChild(cbAdopted);
    return grid2;
  },
  async noChilren() {
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.children_yn = U.id(`noChildren`).checked;
    console.log('noChildren payload', vars);

    O.PREP([await O.mPOST('application/toggle-children-detail', vars)])
      .then(async (res) => {
        U.id('ChildSection').classList.toggle('hide');
        const msg = res['application/toggle-children-detail'].status;
        comp.toast(msg);

        if (U.id(`noChildren`).checked) {
          const ChildrenData = res['application/toggle-children-detail'].data;
          // Preset Marriage
          const presentData = ChildrenData.present;
          this.buildChildTable('present', presentData);
          // -------------------------------
          // Party 1 Marriage
          const p1Data = ChildrenData.party1;
          this.buildChildTable('party1', p1Data);
          // Party 2 Marriage
          if (ChildrenData.party2.length) {
            const p2Data = ChildrenData.party2;
            this.buildChildTable('party2', p2Data);
          }
          // -------------------------------
          // Other Children
          const otherData = ChildrenData.other;
          this.buildChildTable('other', otherData, 1);
        }

        // this.buildChildTable(section, newChildData, section === 'other' ? 1 : 0);
      })
      .catch(console.error);
  },
  async addChild(section) {
    const formHTML = await this.buildChildForm();
    const form = formHTML.outerHTML;
    let otherInfo;
    if (section === 'other') {
      const other = await this.otherChildExtraInfoForm();
      otherInfo = other.outerHTML;
    }
    const sectionCopy = this[`${section}`];

    const div = `
    <div
      id="childBox"
      class="fixed inset-0 z-50 overflow-y-auto bg-opacity-50 bg-black ">

      <div class="card relative transform overflow-hidden">
      <h4>Add new child from ${sectionCopy}</h4>

      <div id="newChild">
      ${form}
      ${otherInfo || ''}
      </div>

      <div
      class="flex justify-center items-center" >
      <button class="btn-outline mr-4" onclick="U.closeModal('childBox')">Cancel</button>
      <button id="addChild-btn" onclick="thsSubTab.submitNewChild('${section}')">Add child</button>

    </div>`;

    const passBox = U.createHTMLElementFromString(div);
    document.body.prepend(passBox);
  },
  async submitNewChild(section) {
    U.loaderBtn(1, 'addChild-btn');
    let isValid = await U.validateForm('newChild');
    if (!isValid) {
      U.loaderBtn(0, 'addChild-btn');
      return;
    }

    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars[`${section}`] = {};
    vars[`${section}`].f_name = U.id('newChild_f_name').value;
    vars[`${section}`].s_name = U.id('newChild_s_name').value;
    vars[`${section}`].gender = U.id('newChild_gender').value;
    vars[`${section}`].dob = U.id('newChild_dob').value;
    if (section === 'other') {
      vars[`${section}`].adopted = U.id('newChild_adopted').checked;
      vars[`${section}`].predeceased = U.id('newChild_predeceased').checked;
      vars[`${section}`].wedlock = U.id('newChild_wedlock').checked;
    }
    console.log(vars);

    O.PREP([await O.mPOST('application/ins-children-detail', vars)])
      .then(async (res) => {
        const msg = res['application/ins-children-detail'].status;
        comp.toast(msg);
        U.closeModal('childBox');
        const newChildData = res['application/ins-children-detail'].data[`${section}`];
        this.buildChildTable(section, newChildData, section === 'other' ? 1 : 0);
      })
      .catch(() => {
        console.error;
        U.loaderBtn(0, 'addChild-btn');
      });
  },
  async removeChild(childID, section) {
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.section = section;
    vars.application_children_uuid = childID;

    O.PREP([await O.mPOST('application/rmv-children-detail', vars)])
      .then(async (res) => {
        const newChildData = res['application/rmv-children-detail'].data[`${section}`];
        const msg = res['application/rmv-children-detail'].status;
        comp.toast(msg);
        this.buildChildTable(section, newChildData, section === 'other' ? 1 : 0);
      })
      .catch(console.error);
  },
  async savePage(nextPrev) {
    const method = 'application/val-app-page';
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.page_name = 'ADs_ChildrenDetails';
    O.PREP([await O.mPOST(method, vars)])
      .then(async (res) => {
        comp.toast(res[method].status);
        thsPage.pageProgress(res[method].data, nextPrev);
      })
      .catch(err => {console.log(err)});
  },
};
