import svg from './svg';

// VALIDATION ==
//https://www.npmjs.com/package/validator
import validator from 'validator';
//Uses a conbo of validator.js and custom valiidations

// Validations Rules List
const validationRules = {
  text: [
    {
      method: 'isLength',
      params: [{min: 1}],
      message: 'Cannot be empty',
    },
  ],
  number: [
    {
      method: 'isNumeric',
      params: [{min: 1}],
      message: 'Cannot be empty',
    },
  ],
  email: [
    {
      method: 'isEmail',
      message: 'Please enter a valid email',
    },
  ],
  textarea: [
    {
      method: 'isLength',
      params: [{min: 5}],
      message: 'Message must be at least 5 characters',
    },
  ],
  mobile: [
    {
      method: 'isMobilePhone',
      params: ['any'],
      message: 'Please enter a valid mobile number',
    },
  ],
  select: [
    {
      method: 'isLength',
      params: [{min: 1}],
      message: 'Cannot be empty',
    },
  ],
  idNumber: [
    {
      method: 'isValidIdNumberZA',
      message: 'Please enter a valid South African ID number',
    },
  ],
  passwordCheck: [
    {
      method: 'isStrongPassword',
      message: 'Password not strong enough.',
    },
  ],
  radio: [
    {
      method: 'isRadioChecked',
      message: 'An option has not been selected.',
    },
  ],
};
const customValidations = {
  isValidIdNumberZA: (value) => {
    const idRegex = /(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/gm;
    return idRegex.test(value);
  },
  isRadioChecked: (radioName) => {
    let checked = false;
    let radioOptions = document.getElementsByName(`${radioName}`);
    radioOptions.forEach((radio) => {
      if (radio.checked === true) {
        checked = true;
      }
    });
    return checked;
  },
};

export default {
  async urlToObj(url) {
    const params = new URLSearchParams(url);
    const result = {};

    params.forEach((value, key) => {
      result[key] = value;
    });

    return result;
  },
  async uuidvMain() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
  id(string) {
    return document.getElementById(string);
  },
  // getCookie(cKey) {
  //   let name = cKey + '=';
  //   let decodedCookie = decodeURIComponent(document.cookie);
  //   let ca = decodedCookie.split(';');
  //   for (let cookie of ca) {
  //     let c = cookie;
  //     while (c.charAt(0) == ' ') {
  //       c = c.substring(1);
  //     }
  //     if (c.indexOf(name) == 0) {
  //       return c.substring(name.length, c.length);
  //     }
  //   }
  //   return 0;
  // },
  // createCookie(cKey, cValue) {
  //   return new Promise((resolve, reject) => {
  //     document.cookie = `${cKey}=${cValue};secure,`;
  //     resolve(); // Resolve the promise when the cookie is set
  //   });
  // },
  scroll() {
    window.scrollTo(0, 0);
  },
  downloadFile(ab, fileName) {
    const byteArray = new Uint8Array(ab);
    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(
      new Blob([byteArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
    );
    a.download = `${fileName}.XLSX`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  },
  cfd(headers, obj) {
    return new Promise((resolve, reject) => {
      if (obj.constructor !== Object) {
        reject('There was an error parsing variables ');
      } else {
        const vars = {
          ...headers,
          ...obj,
        };
        const nfd = new FormData();
        for (const key in vars) {
          if (vars.hasOwnProperty(key)) {
            if (key === 'ewttgpvwwkf') {
              nfd.append('UUID', vars[key]);
            } else {
              isNum(vars[key]) && !keyExceptions.includes(key) && (vars[key] = parseInt(vars[key]));
              nfd.append(key, vars[key]);
            }
          }
        }
        resolve(nfd);
      }
    });
  },
  hide(elID) {
    const el = document.getElementById(elID);
    if (!el.classList.contains('hide')) {
      el.classList.add('hide');
    }
  },
  unhide(elID) {
    const el = document.getElementById(elID);
    if (el.classList.contains('hide')) {
      el.classList.remove('hide');
    }
  },
  toggleHide(elID) {
    document.getElementById(elID).classList.toggle('hide');
  },
  divAtt(element, att, value) {
    const div = document.createElement(element);
    if (att) {
      const attribute = document.createAttribute(att);
      attribute.value = value || '';
      div.setAttributeNode(attribute);
    }
    return div;
  },
  async formatDate(dateString) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const dateParts = dateString.split('-'); // Split the date string into components
    const year = dateParts[0];
    const month = months[parseInt(dateParts[1], 10) - 1];
    const day = parseInt(dateParts[2], 10); // Convert day to number to remove any leading zeros
    return `${day} ${month} ${year}`;
  },
  getTextByUuid(dataArray, uuidName, uuidValue, returnName) {
    const matchingObject = dataArray.find((obj) => obj[`${uuidName}`] === uuidValue);

    return matchingObject ? matchingObject[`${returnName}`] : undefined;
  },
  navSel(navID) {
    const navItems = document.querySelectorAll('.nav-item');
    navItems.forEach((navItem) => {
      navItem.classList.remove('nav-active');
    });

    if (navID) {
      document.getElementById(navID).classList.add('nav-active');
    }
  },
  buildOptsDD(data, value, text, placeholder) {
    let opts;
    let placeholdeMsg = placeholder ? placeholder : 'Please choose an option---';

    if (data.length) {
      opts = `<option value="">${placeholdeMsg}</option>
      ${data.map((arr) => `<option value="${arr[`${value}`]}">${arr[`${text}`]}</option>`).join('')}`;
    } else {
      opts = `<option value="">No options available --</option>`;
    }

    return opts;
  },
  createHTMLElementFromString(htmlString) {
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(htmlString, 'text/html');

    // Return the first element in the parsed document
    return parsedDocument.body.firstChild;
  },
  loaderBtn(io, btnID) {
    const button = this.id(btnID);

    if (io) {
      button.disabled = true;
      let spinner = svg.loadingBtn(btnID);
      button.prepend(spinner);
    } else {
      button.disabled = false;
      let spinnerID = this.id(`${btnID}_loading`);
      button.removeChild(spinnerID);
    }
  },
  closeModal(elID) {
    const modal = this.id(elID);
    modal && document.body.removeChild(modal);
  },
  async IdPassToggle(elID) {
    const toggle = U.id(`${elID}_toggle`);
    const input = U.id(`${elID}`);
    const toggleLable = U.id(`${elID}_toggle_label`);

    if (toggle.checked === true) {
      input.setAttribute('val', 'idNumber');
      toggleLable.innerHTML = 'ID Number';
    } else {
      input.setAttribute('val', 'text');
      toggleLable.innerHTML = 'Passport Number';
    }
  },
  async viewPass(inpID, eyeID) {
    const input = id(inpID);
    const eyeDiv = id(eyeID);
    const eye = this.id('eyeSVG');
    const eyeClosed = this.id('eyeClosedSVG');
    if (input.type === 'password') {
      input.type = 'text';

      eyeDiv.removeChild(eye);
      const eyeClosedSVG = await compile.eyeClosed();
      eyeDiv.appendChild(eyeClosedSVG);

      // eye.classList.add('iconOn');
    } else {
      input.type = 'password';
      eyeDiv.removeChild(eyeClosed);
      const eyeSVG = await compile.eyeClosed();
      eyeDiv.appendChild(eyeSVG);
      // eye.classList.remove('iconOn');
    }
  },
  updateProgressSteps(newCurrentStep) {
    //Remove all active
    let activeArr = document.querySelectorAll('.progressActive');
    activeArr.forEach((element) => {
      element.classList.remove('progressActive');
    });

    // Add active to all before and including step
    for (let i = 1; i <= newCurrentStep; i++) {
      U.id(`${i}_progressNum`).classList.add('progressActive');
      if (U.id(`${i}_progressBar`)) {
        U.id(`${i}_progressBar`).classList.add('progressActive');
      }
    }
  },
  async validateForm(formSelector, btnID) {
    const form = document.getElementById(formSelector);
    let isValid = true;

    const inputElements = form.querySelectorAll('input, textarea, select');
    inputElements.forEach((input) => {
      const fieldName = input.id ? input.id : input.name;
      const valAttribute = input.getAttribute('val');
      const fbElement = form.querySelector(`[fb-for="${fieldName}"]`);
      const isRequired = input.hasAttribute('required');

      // Clear previous error messages and borders =========
      if (fbElement) {
        fbElement.textContent = '';
      }
      input.classList.remove('input-error');

      //Check inputs against Validation Rules ================
      if (validationRules.hasOwnProperty(valAttribute) && isRequired) {
        const rules = validationRules[valAttribute];
        const value = input.value.trim();

        rules.forEach((rule) => {
          let isValidField = false;

          if (validator[rule.method]) {
            // Validation method from validator.js library
            if (rule.params && typeof rule.params[Symbol.iterator] === 'function') {
              isValidField = validator[rule.method](value, ...rule.params);
            } else {
              isValidField = validator[rule.method](value);
            }
          } else if (customValidations[rule.method]) {
            // Custom validation method
            if (input.type !== 'radio') {
              isValidField = customValidations[rule.method](value);
            } else {
              isValidField = customValidations[rule.method](fieldName);
            }
          }

          if (!isValidField) {
            if (fbElement) {
              fbElement.textContent = rule.message;
            }
            if (input.type !== 'radio') {
              input.classList.add('input-error');
            }
            isValid = false;
          }
        });
      }
    });

    if (btnID) {
      U.loaderBtn(0, `${btnID}`);
    }

    return isValid;
  },
  async addCivillian(infoObj) {
    // --------------------------------
    // infoObj vars names:
    // Compulsary (and dont need in infoObj): f_name, s_name, IDpass
    // --------------------------------
    // Options: email_address, cell_nr, dob, initials, title, gener uuid, initials, prev_s_name, alt_title, alt_email_address, tell_nr, fax_nr, physical_address, postal_address, textarea(Supply title of textarea)

    await this.civillianForm(infoObj);
    return new Promise((resolve, reject) => {
      this.id('addCivillian-btn').addEventListener('click', async () => {
        try {
          this.id('addCivillian-btn').disabled = true;
          const result = await U.addNewCivillian();
          if (!result) {
            throw new Error('Failed to add new user');
          }
          resolve(result);
        } catch (error) {
          this.id('addCivillian-btn').disabled = false;
          alert('Bad');
          // Error box here
          // reject(error);
        }
      });

      this.id('cancelCivillian-btn').addEventListener(
        'click',
        () => {
          resolve('Canceled');
        },
        {once: true}
      );
    });
  },
  async civillianForm(infoObj) {
    // ----------------------------------
    let form = '';
    // Organisation
    const orgYN = Object.keys(infoObj).some((key) => key.includes('organisation'));

    if (orgYN) {
      // build toggle here
      const toggleDiv = this.divAtt('div');
      let indOrgToggle = await comp.toggleLarge('indOrgToggle', 0, 'Individual', 'Organisation', 'U.indOrgToggle()');
      toggleDiv.appendChild(indOrgToggle);
      form += toggleDiv.outerHTML;

      // build org form here
      const grid1 = this.divAtt('div', 'class', 'res-grid-cols-2 hide');
      grid1.id = 'newOrg';

      const companyName = await comp.inputText(`civ-orgName`, '', 'Organisation name', 1);
      grid1.appendChild(companyName);

      const orgContact = await comp.inputText(`civ-orgContact`, '', 'Organisation contact', 1);
      grid1.appendChild(orgContact);
      const orgNotes = await comp.textarea('civ-orgNotes', '', 'Notes', 1);
      grid1.appendChild(orgNotes);

      form += grid1.outerHTML;
    }

    // ----------------------------------
    // Civillian
    const grid2 = this.divAtt('div', 'class', 'res-grid-cols-2');
    grid2.id = 'newCivillian';

    const f_name = await comp.inputText(`civ-f_name`, '', 'Full names', 1);
    const s_name = await comp.inputText(`civ-s_name`, '', 'Surname', 1);
    const IDpass = await comp.inputIDPassport('civ-IDpass', true, '', 1);
    grid2.appendChild(f_name);
    grid2.appendChild(s_name);
    grid2.appendChild(IDpass);

    Object.keys(communal).length === 0 && (await U.getCommunalDropdowns());
    for (const input of Object.keys(infoObj)) {
      switch (input) {
        case 'email_address':
          const emailInput = await comp.inputEmail('civ-email_address', '', 'Email', 1);
          grid2.appendChild(emailInput);
          break;

        case 'cell_nr':
          const mobInput = await comp.inputTel('civ-cell_nr', '', 'Mobile number', 1);
          grid2.appendChild(mobInput);
          break;

        case 'dob':
          let dob = await comp.inputDate(`civ-dob`, '', 'Date of birth: ', 1);
          grid2.appendChild(dob);
          break;

        case 'relationship':
          const relDD = await comp.selectRelationship('civ-relationship', infoObj.relationship, 1);
          grid2.appendChild(relDD);
          break;

        case 'textarea':
          const textarera = await comp.textarea('civ-textarea', '', infoObj[input], 1);
          grid2.appendChild(textarera);
          break;

        case 'physical_address':
          const grid4 = U.divAtt('div', 'class', 'res-grid-cols-1 col-span-2');
          const physHeading = U.divAtt('h6', 'class', 'mt-3');
          physHeading.innerHTML = 'Physical address';
          let phy_street = await comp.inputText(`civ-physical_address`, '', 'Street address: ', 1);
          let phy_type = await comp.inputText(`civ-physical_address_type`, '', 'Complex/Building (optional): ', 0);
          grid4.appendChild(physHeading);
          grid4.appendChild(phy_street);
          grid4.appendChild(phy_type);
          grid2.appendChild(grid4);
          const physGrid = U.divAtt('div', 'class', 'res-grid-cols-2 col-span-2');
          let phy_suburb = await comp.inputText(`civ-physical_suburb`, '', 'Suburb: ', 0);
          let phy_town = await comp.inputText(`civ-physical_town`, '', 'Town/City: ', 0);
          let phy_provDD = await comp.select(`civ-physical_province`, communal.provinces, 'province_uuid', 'long_text', 'Province', 1);
          let phy_code = await comp.inputText(`civ-physical_code`, '', 'Area code: ', 0);
          physGrid.appendChild(phy_suburb);
          physGrid.appendChild(phy_town);
          physGrid.appendChild(phy_provDD);
          physGrid.appendChild(phy_code);
          grid2.appendChild(physGrid);
          break;

        case 'postal_address':
          const grid4pst = U.divAtt('div', 'class', 'res-grid-cols-1 col-span-2');
          const pstsHeading = U.divAtt('h6', 'class', 'mt-3');
          pstsHeading.innerHTML = 'Postal address';
          let pst_street = await comp.inputText(`civ-postal_address`, '', 'Street address: ', 1);
          let pst_type = await comp.inputText(`civ-postal_address_type`, '', 'Complex/Building (optional): ', 0);
          grid4pst.appendChild(pstsHeading);
          grid4pst.appendChild(pst_street);
          grid4pst.appendChild(pst_type);
          grid2.appendChild(grid4pst);
          const pstsGrid = U.divAtt('div', 'class', 'res-grid-cols-2 col-span-2');
          let pst_suburb = await comp.inputText(`civ-postal_suburb`, '', 'Suburb: ', 0);
          let pst_town = await comp.inputText(`civ-postal_town`, '', 'Town/City: ', 0);
          let pst_provDD = await comp.select(`civ-postal_province`, communal.provinces, 'province_uuid', 'long_text', 'Province', 1);
          let pst_code = await comp.inputText(`civ-postal_code`, '', 'Area code: ', 0);
          pstsGrid.appendChild(pst_suburb);
          pstsGrid.appendChild(pst_town);
          pstsGrid.appendChild(pst_provDD);
          pstsGrid.appendChild(pst_code);
          grid2.appendChild(pstsGrid);
          break;

        case 'organisation':
          //Do nothing
          break;

        default:
          const otherTextInput = await comp.inputText(`civ-${input}`, '', `${input}`, infoObj[input]);
          grid2.appendChild(otherTextInput);
          break;
      }
    }

    //   postal_address||null,
    //   postal_suburb||null,
    //  .postal_town||null,
    //  postal_province||null, //uuid
    //  postal_code||null,

    form += grid2.outerHTML;

    const div = `
    <div
      id="civillianForm"
      class="fixed inset-0 z-50 overflow-y-auto bg-opacity-50 bg-black ">

      <div class="card relative transform overflow-hidden">
      <h4>Information</h4>
    
      ${form}
    
      <div
      class="flex justify-center items-center" >
      <button id="cancelCivillian-btn" class="btn-outline mr-4" onclick="U.closeModal('civillianForm') ">Cancel</button>
      <button id="addCivillian-btn">Add</button>

    </div>`;

    const passBox = U.createHTMLElementFromString(div);
    document.body.prepend(passBox);
  },
  async addNewCivillian() {
    // ---------------------------------
    // Validate
    U.loaderBtn(1, 'addCivillian-btn');

    let isValid = false;
    let isOrg = false;

    if (U.id('indOrgToggle') && U.id('indOrgToggle').checked === true) {
      isOrg = true;
      isValid = await U.validateForm('newOrg');
    } else {
      isValid = await U.validateForm('newCivillian');
    }

    // ------
    if (!isValid) {
      U.loaderBtn(0, 'addCivillian-btn');
      return false;
    }

    // ---------------------------------
    // Collect vars

    const vars = {};

    const form = document.getElementById('newCivillian');
    const inputElements = form.querySelectorAll('input, textarea, select');
    inputElements.forEach((input) => {
      const varName = input.id.split('-');
      if (input.type === 'radio') {
        if (input.checked) {
          vars[`${varName[1]}`] = input.value;
        }
      } else if (input.id === 'civ-IDpass') {
        if (U.id(`civ-IDpass_toggle`).checked === true) {
          vars.id_nr = U.id(`civ-IDpass`).value;
        } else {
          vars.passport_nr = U.id(`civ-IDpass`).value;
        }
      } else if (input.id === 'civ-relationship') {
        if (U.id('civ-relationship_party1') && U.id('civ-relationship_party1').checked === true) {
          vars.rel_party1 = U.id(`civ-relationship`).value;
        }
        if (U.id('civ-relationship_party2') && U.id('civ-relationship_party2').checked === true) {
          vars.rel_party2 = U.id(`civ-relationship`).value;
        }
        if (!U.id('civ-relationship_party1')) {
          vars.rel_party1 = U.id(`civ-relationship`).value;
        }
      } else if (input.id === 'civ-IDpass_toggle' || input.id === 'civ-relationship_party1' || input.id === 'civ-relationship_party2' || input.id === 'civ-civ-organisationDiv') {
        //Do nothing
      } else {
        vars[`${varName[1]}`] = input.value;
      }
    });

    // ---------------------------------
    // make call
    try {
      let data = {};

      if (!isOrg) {
        const res = await O.PREP([await O.mPOST('communal/ins_user', vars)]);
        data = res['communal/ins_user'].data;
      }

      //rettur data not submited to ins_user ---
      U.id('civ-textarea') && (data.textarea = U.id(`civ-textarea`).value);
      U.id('civ-orgName') && (data.orgName = U.id(`civ-orgName`).value);
      U.id('civ-orgContact') && (data.orgContact = U.id(`civ-orgContact`).value);
      U.id('civ-orgNotes') && (data.orgNotes = U.id(`civ-orgNotes`).value);

      U.closeModal('civillianForm');
      return data;
    } catch (error) {
      console.error(error);
      U.loaderBtn(0, 'addCivillian-btn');
      return false; // Indicate that form submission failed
      // throw error;
      // throw false;
    }
  },
  indOrgToggle() {
    this.id('newOrg').classList.toggle('hide');
    this.id('newCivillian').classList.toggle('hide');
  },
  async getCommunalDropdowns() {
    return new Promise(async (resolve, reject) => {
      const vars = {};
      const methods = [];
      methods.push(await O.mPOST('communal/dropdowns', vars));

      O.PREP(methods)
        .then((res) => {
          window.communal = res['communal/dropdowns'].data;
          resolve(window.communal);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  // ---------------------------------
  //Button loader addded to forrm val?
  formInProgressVal(formName, submitBtn) {
    const form = document.getElementById(formName);
    submitBtn = document.getElementById(submitBtn);
    let isValid = true;

    // ---------------------------------
    // Button Active or Disabled
    if (isValid) {
      submitBtn.disabled = false;
    } else {
      submitBtn.disabled = true;
    }
  },
};
