import comp from '../components/barrel';
import svg from '../js/svg';

export default {
  application_uuid: null,
  testament: null,
  party1_name: null,
  party1_user_uuid: null,
  party1_soleheir_uuid: null,
  party1_soleheir_name: null,
  party2_name: null,
  party2_user_uuid: null,
  party2_soleheir_uuid: null,
  party2_soleheir_name: null,
  async prepare() {
    this.application_uuid = thsPage.vars.application_uuid;
    const vars = {};
    vars.application_uuid = this.application_uuid;
    this.recipe.methods.push(await O.mPOST('application/get-app-summary', vars));
    this.recipe.methods.push(await O.mPOST('application/get-heir_details', vars));

    // this.recipe.methods.push(await O.mPOST('application/get-sole-heir', vars));
    // this.recipe.methods.push(await O.mPOST('application/get-heir-list', vars));
    // this.recipe.methods.push(await O.mPOST('application/get-obliteration-list', vars));

    // Clear table to prevent duplication when sum ratios (Better solution needed)
    if (await U.id('heir_list')) {
      U.id('heir_list').innerHTML = '';
    }
  },
  async render() {
    const rec = this.recipe.methods;

    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    Object.keys(communal).length === 0 && (await U.getCommunalDropdowns());

    const summary = data['application/get-app-summary'].data;
    this.testament = summary[0].testament;
    const heirs = data['application/get-heir_details'].data;
    // const heirs = data['application/get-sole-heir'].data;
    // const heirList = data['application/get-heir-list'].data;
    // const famOblitList = data['application/get-obliteration-list'].data;

    this.party1_name = heirs.party1.name;
    this.party1_user_uuid = heirs.party1.user_uuid;
    this.party1_soleheir_name = heirs.party1.heir_name;
    this.party1_soleheir_uuid = heirs.party1.heir_uuid;

    // -------------------------------
    //Sole heir section
    if (this.testament === 'Joint') {
      this.party2_user_uuid = heirs.party2.user_uuid;
      this.party2_name = heirs.party2.name;
      this.party2_soleheir_name = heirs.party2.heir_name;
      this.party2_soleheir_uuid = heirs.party2.heir_uuid;

      await this.buildSoleHeirs(heirs);
    } else {
      U.hide('soleHeirDiv');
    }

    // -------------------------------
    // Allocate heirs section

    await this.buildHeirListTable(heirs.heir_list);
    this.checkRatio('noUpdt');
    this.testament === 'Joint' ? (U.id('hB').innerHTML = 'B. Simultaneous Death') : (U.id('hB').innerHTML = 'A. Heir Ratio');
    // -------------------------------
    // Family Obliteration section
    await this.buildFamOblitHeirListTable(heirs.obliteration_list);
    this.checkRatio('noUpdt');
    this.testament === 'Joint' ? (U.id('hC').innerHTML = 'C. Family Obliteration') : (U.id('hC').innerHTML = 'B. Family Obliteration');
  },
  async buildSoleHeirs(heirs) {
    const soleHeirDiv = U.id('soleHeirDiv');
    // -------------------------------
    // Party 1
    const p1Div = U.id('party1Div');
    const p1 = U.divAtt('h6', 'class', '');
    p1.innerHTML = `Party 1 - ${this.party1_name} sole heir`;
    const label = `Party 2 (${this.party2_name}) sole heir if party 1 first dying`;
    let p1sole = true;

    if (heirs.party1.heir_uuid) {
      p1sole = this.party2_user_uuid === heirs.party1.heir_uuid ? true : false;
    }
    const p1CheckBox = await comp.inputCheckbox('party1SoleHeirCheck', p1sole, label);
    p1Div.appendChild(p1);
    p1Div.appendChild(p1CheckBox);
    U.id('party1SoleHeirCheck').setAttribute('onchange', `thsSubTab.checkSoleHeirs('party1')`);

    // Other sole heir btn ---
    const addHeirBtn = U.divAtt('button', 'class', 'btn-icon btn-transparent mt-2');
    addHeirBtn.setAttribute('onclick', `thsSubTab.addSoleHeirs('party1')`);
    addHeirBtn.id = `btnparty1SoleHeir`;
    const btnText = U.divAtt('span');
    btnText.innerHTML = 'Change sole heir';
    const repeatSVG = await svg.repeat();
    addHeirBtn.appendChild(repeatSVG);
    addHeirBtn.appendChild(btnText);
    p1Div.appendChild(addHeirBtn);

    // Add name tag and button if party 2 not sole heir
    if (!p1sole && heirs.party1.heir_uuid) {
      this.buildNameTag('party1');
    }

    // -------------------------------
    // Party 2
    const p2Div = U.id('party2Div');
    const p2 = U.divAtt('h6', 'class', '');
    p2.innerHTML = `Party 2 - ${this.party2_name} sole heir`;
    const label2 = `Party 1 (${this.party1_name}) sole heir if party 2 first dying`;
    let p2sole = true;

    if (heirs.sole_heir_party2) {
      p2sole = this.party1_user_uuid === heirs.party2.heir_uuid ? true : false;
    }
    const p2CheckBox = await comp.inputCheckbox('party2SoleHeirCheck', p2sole, label2);
    p2Div.appendChild(p2);
    p2Div.appendChild(p2CheckBox);
    U.id('party2SoleHeirCheck').setAttribute('onchange', `thsSubTab.checkSoleHeirs('party2')`);

    // Other sole heir btn ---
    const addHeirBtn2 = U.divAtt('button', 'class', 'btn-icon btn-transparent mt-2');
    addHeirBtn2.setAttribute('onclick', `thsSubTab.addSoleHeirs('party2')`);
    addHeirBtn.id = `btnparty2SoleHeir`;
    const btnText2 = U.divAtt('span');
    btnText2.innerHTML = 'Change sole heir';
    const repeatSVG2 = await svg.repeat();
    addHeirBtn2.appendChild(repeatSVG2);
    addHeirBtn2.appendChild(btnText2);
    p2Div.appendChild(addHeirBtn2);

    // Add name tag annd button if party 2 not sole heir
    if (!p2sole && heirs.party2.heir_uuid) {
      this.buildNameTag('party2');
    }
    // -------------------------------
  },
  async buildNameTag(forParty) {
    const nameTagSoleHeir = U.divAtt('div', 'class', 'nameTag mt-4');
    nameTagSoleHeir.id = `nameTag${forParty}SoleHeir`;
    nameTagSoleHeir.innerHTML = forParty === 'party1' ? this.party1_soleheir_name : this.party2_soleheir_name;
    U.id(`${forParty}Div`).appendChild(nameTagSoleHeir);
  },
  checkSoleHeirs(forParty) {
    const pCheckBoxID = forParty === 'party1' ? 'party1SoleHeirCheck' : 'party2SoleHeirCheck';
    const cb = U.id(`${pCheckBoxID}`);
    if (cb.checked === true) {
      // remove old heir
      if (U.id(`nameTag${forParty}SoleHeir`)) {
        U.id(`${forParty}Div`).removeChild(U.id(`nameTag${forParty}SoleHeir`));
      }
    }
    this.updateSoleHeirs();
  },
  // addSoleHeirs(forParty) {
  //   // const pCheckBoxID = forParty === 'party1' ? 'party1SoleHeirCheck' : 'party2SoleHeirCheck';
  //   // const cb = U.id(`${pCheckBoxID}`);

  //   // if (cb.checked === true) {
  //   //   this.updateSoleHeirs();
  //   //   // remove old heir
  //   //   if (U.id(`nameTag${forParty}SoleHeir`)) {
  //   //     U.id(`${forParty}Div`).removeChild(U.id(`nameTag${forParty}SoleHeir`));
  //   //   };
  //   // } else {
  //   //   this.checkUser(forParty);
  //   // };

  //   this.checkUser(forParty);
  // },
  async addSoleHeirs(forParty) {
    const inforObj = {
      relationship: [this.party1_name],
    };
    this.party2_name && inforObj.relationship.push(this.party2_name);
    const data = await U.addCivillian(inforObj);

    if (data && data !== 'Canceled') {
      // remove old heir
      if (U.id(`nameTag${forParty}SoleHeir`)) {
        U.id(`${forParty}Div`).removeChild(U.id(`nameTag${forParty}SoleHeir`));
      }
      //set new soleHeir
      (await forParty) === 'party1' ? (this.party1_soleheir_uuid = data.user_uuid) : (this.party2_soleheir_uuid = data.user_uuid);
      (await forParty) === 'party1' ? (this.party1_soleheir_name = data.full_name) : (this.party2_soleheir_name = data.full_name);
      // submit new heir
      this.buildNameTag(forParty);
      this.updateSoleHeirs();
    } else {
      //cancelled adding new sole heir
      // U.id(`${forParty}SoleHeirCheck`).checked = true;
    }
  },
  async updateSoleHeirs() {
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.party1_heir_yn = U.id('party1SoleHeirCheck').checked;
    if (vars.party1_heir_yn) {
      vars.party1_heir_uuid = U.id('nameTagParty1SoleHeir') ? this.party2_user_uuid : this.party1_soleheir_uuid;
    }
    vars.party2_heir_yn = U.id('party2SoleHeirCheck').checked;
    if (vars.party2_heir_yn) {
      vars.party2_heir_uuid = vars.party2_heir_yn ? this.party1_user_uuid : this.party2_soleheir_uuid;
    }
    // vars.sell_assets_yn = U.id('sellAllAssets').checked; removed

    console.log('ups-sole vars', vars);

    O.PREP([await O.mPOST('application/ups_sole_heir', vars)]).then((res) => {
      const data = res['application/ups_sole_heir'].data;
      const msg = res['application/ups_sole_heir'].status;
      comp.toast(msg);
    });
  },

  //Heir List -------
  async buildHeirListTable(data) {
    const table = U.id(`heir_list`);
    table.innerHTML = '';

    // HEADER
    const thead = U.divAtt('thead');
    const headRow = thead.insertRow();
    const nameH = headRow.insertCell(0);
    nameH.innerHTML = 'Name';
    const relH = headRow.insertCell(1);
    relH.innerHTML = 'Relationship';
    const ratioH = headRow.insertCell(2);
    ratioH.innerHTML = 'Ratio';
    const removeH = headRow.insertCell(3);
    removeH.innerHTML = 'Remove';

    thead.appendChild(headRow);
    table.appendChild(thead);

    // BODY
    const tbody = U.divAtt('tbody');
    table.appendChild(tbody);
    if (data.children.length) {
      const childHrow = tbody.insertRow();
      const childrow = childHrow.insertCell(0);
      childrow.classList.add('subTHead');
      childrow.innerHTML = 'Children';
      childrow.colSpan = 4;

      data.children.forEach(async (row) => {
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = row.name;
        const rel = childRow.insertCell(1);
        rel.innerHTML = (await U.getTextByUuid(communal.relationships, 'relationship_uuid', row.relationship, 'short_text')) || '';
        const ratio = childRow.insertCell(2);
        const ratioInpt = U.divAtt('input', 'class', 'heirRatio');
        ratioInpt.setAttribute('onkeyup', 'thsSubTab.checkRatio()');
        ratioInpt.setAttribute('value', !row.share_ratio ? 0 : `${row.share_ratio}`);
        ratioInpt.id = row.heir_uuid;
        ratio.appendChild(ratioInpt);
        childRow.insertCell(3);
      });
    }

    if (data.heirs.length) {
      const otherHrow = tbody.insertRow();
      const otherRow = otherHrow.insertCell(0);
      otherRow.classList.add('subTHead');
      otherRow.innerHTML = 'Other Hiers';
      otherRow.colSpan = 4;

      data.heirs.forEach(async (row) => {
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = row.name;
        const rel = childRow.insertCell(1);
        rel.innerHTML = (await U.getTextByUuid(communal.relationships, 'relationship_uuid', row.relationship, 'short_text')) || '';
        const ratio = childRow.insertCell(2);
        const ratioInpt = U.divAtt('input', 'class', 'heirRatio');
        ratioInpt.setAttribute('onkeyup', 'thsSubTab.checkRatio()');
        ratioInpt.setAttribute('value', !row.share_ratio ? 0 : `${row.share_ratio}`);
        ratioInpt.id = row.heir_uuid;
        ratio.appendChild(ratioInpt);
        const removeIcon = childRow.insertCell(3);
        const onclick = `thsSubTab.removeHeir('${row.heir_uuid}')`;
        const linkSVG = svg.trash(onclick);
        removeIcon.appendChild(linkSVG);
      });
    }

    if (data.company.length) {
      // const otherHrow = tbody.insertRow();
      // const otherRow = otherHrow.insertCell(0);
      // otherRow.classList.add('subTHead');
      // otherRow.innerHTML = 'Other Hiers - Organisations';
      // otherRow.colSpan = 4;

      data.company.forEach(async (row) => {
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = row.name;
        const rel = childRow.insertCell(1);
        rel.innerHTML = 'Organisation';
        const ratio = childRow.insertCell(2);
        const ratioInpt = U.divAtt('input', 'class', 'heirRatio');
        ratioInpt.setAttribute('onkeyup', 'thsSubTab.checkRatio()');
        ratioInpt.setAttribute('value', !row.share_ratio ? 0 : `${row.share_ratio}`);
        ratioInpt.id = row.heir_uuid;
        ratio.appendChild(ratioInpt);
        const removeIcon = childRow.insertCell(3);
        const onclick = `thsSubTab.removeHeir('${row.heir_uuid}')`;
        const linkSVG = svg.trash(onclick);
        removeIcon.appendChild(linkSVG);
      });
    }

    if (!data.heirs.length && !data.children.length && !data.company.length) {
      //No  Heirs ---
      const heirRow = tbody.insertRow();
      const noHeir = heirRow.insertCell(0);
      noHeir.innerHTML = 'No heirs added.';
      noHeir.colSpan = 4;
    }

    const totalRow = tbody.insertRow();
    totalRow.classList.add('heirTotal');
    const totalH = totalRow.insertCell(0);
    totalH.innerHTML = 'Total';
    totalRow.insertCell(1);
    const total = totalRow.insertCell(2);
    total.id = 'totalRatio';
    totalRow.insertCell(3);

    this.checkRatio('noUpdt');
  },
  checkRatio(noUpdt) {
    let sum = 0;
    const ratios = document.querySelectorAll('.heirRatio');

    ratios.forEach((input) => {
      let value = parseFloat(input.value);
      if (isNaN(value) || value < 0) {
        input.value = '';
      }
      sum += Number(input.value) || 0;
    });

    U.id('totalRatio').innerHTML = sum;
    if (sum === 100) {
      U.id('totalRatio').classList.remove('error');
      if (!noUpdt) {
        this.updateHeirRatios();
      }
    } else {
      U.id('totalRatio').classList.add('error');
    }
  },
  async addHeir() {
    const inforObj = {
      relationship: [this.party1_name],
      organisation: 1,
    };
    this.party2_name && inforObj.relationship.push(this.party2_name);
    const data = await U.addCivillian(inforObj);
    if (data && data !== 'Canceled') {
      this.submitHeir(data);
    }
  },
  async submitHeir(data) {
    const vars = {};
    vars.application_uuid = this.application_uuid;

    if (data.user_uuid) {
      vars.heir_uuid = data.user_uuid;
    } else {
      vars.other = {
        name: data.orgName,
        contact: data.orgContact,
        notes: data.orgNotes,
      };
    }

    O.PREP([await O.mPOST('application/ins-heir-list', vars)])
      .then(async (res) => {
        const msg = res['application/ins-heir-list'].status;
        comp.toast(msg);

        const newHeirData = res['application/ins-heir-list'].data;
        this.buildHeirListTable(newHeirData);
      })
      .catch(() => {
        console.error;
        U.loaderBtn(0, 'newHeir-btn');
      });
  },
  async removeHeir(heirID) {
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.application_heirs_uuid = heirID;

    O.PREP([await O.mPOST('application/rmv-heir-list', vars)])
      .then(async (res) => {
        const newHeirData = res['application/rmv-heir-list'].data;
        const msg = res['application/rmv-heir-list'].status;
        comp.toast(msg);
        this.buildHeirListTable(newHeirData);
      })
      .catch(console.error);
  },
  async updateHeirRatios() {
    const ratios = document.querySelectorAll('.heirRatio');
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.heirs = [];

    ratios.forEach((radio) => {
      let dataObj = {};
      dataObj.heir_uuid = radio.id;
      dataObj.share_ratio = radio.value;
      vars.heirs.push(dataObj);
    });
    console.log(vars);
    O.PREP([await O.mPOST('application/ups-heir-list', vars)])
      .then(async (res) => {
        const newHeirData = res['application/ups-heir-list'].data;
        const msg = res['application/ups-heir-list'].status;
        comp.toast(msg);
      })
      .catch(console.error);
  },
  //Heir List -------
  async buildFamOblitHeirListTable(data) {
    const table = U.id(`famOblit_list`);
    table.innerHTML = '';

    // HEADER
    const thead = U.divAtt('thead');
    const headRow = thead.insertRow();
    const nameH = headRow.insertCell(0);
    nameH.innerHTML = 'Name';
    const relH = headRow.insertCell(1);
    relH.innerHTML = 'Relationship';
    const ratioH = headRow.insertCell(2);
    ratioH.innerHTML = 'Ratio';
    const removeH = headRow.insertCell(3);
    removeH.innerHTML = 'Remove';

    thead.appendChild(headRow);
    table.appendChild(thead);

    // BODY
    const tbody = U.divAtt('tbody');
    table.appendChild(tbody);

    if (data.heirs.length) {
      data.heirs.forEach(async (row) => {
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = row.name;
        const rel = childRow.insertCell(1);
        rel.innerHTML = (await U.getTextByUuid(communal.relationships, 'relationship_uuid', row.relationship, 'short_text')) || '';
        const ratio = childRow.insertCell(2);
        const ratioInpt = U.divAtt('input', 'class', 'FamOblitHeirRatio');
        ratioInpt.setAttribute('onkeyup', 'thsSubTab.checkFamOblitRatio()');
        ratioInpt.setAttribute('value', !row.share_ratio ? 0 : `${row.share_ratio}`);
        ratioInpt.id = row.heir_uuid;
        ratio.appendChild(ratioInpt);
        const removeIcon = childRow.insertCell(3);
        const onclick = `thsSubTab.removeFamOblitHeir('${row.heir_uuid}')`;
        const linkSVG = svg.trash(onclick);
        removeIcon.appendChild(linkSVG);
      });
    }

    if (data.company.length) {
      data.company.forEach(async (row) => {
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = row.name;
        const rel = childRow.insertCell(1);
        rel.innerHTML = 'Organisation';
        const ratio = childRow.insertCell(2);
        const ratioInpt = U.divAtt('input', 'class', 'FamOblitHeirRatio');
        ratioInpt.setAttribute('onkeyup', 'thsSubTab.checkFamOblitRatio()');
        ratioInpt.setAttribute('value', !row.share_ratio ? 0 : `${row.share_ratio}`);
        ratioInpt.id = row.heir_uuid;
        ratio.appendChild(ratioInpt);
        const removeIcon = childRow.insertCell(3);
        const onclick = `thsSubTab.removeFamOblitHeir('${row.heir_uuid}')`;
        const linkSVG = svg.trash(onclick);
        removeIcon.appendChild(linkSVG);
      });
    }

    if (!data.heirs.length && !data.company.length) {
      //No  Heirs ---
      const heirRow = tbody.insertRow();
      const noHeir = heirRow.insertCell(0);
      noHeir.innerHTML = 'No heirs added.';
      noHeir.colSpan = 4;
    }

    const totalRow = tbody.insertRow();
    totalRow.classList.add('heirTotal');
    const totalH = totalRow.insertCell(0);
    totalH.innerHTML = 'Total';
    totalRow.insertCell(1);
    const total = totalRow.insertCell(2);
    total.id = 'FamOblittotalRatio';
    totalRow.insertCell(3);

    this.checkFamOblitRatio('noUpdt');
  },
  checkFamOblitRatio(noUpdt) {
    let sum = 0;
    const ratios = document.querySelectorAll('.FamOblitHeirRatio');

    ratios.forEach((input) => {
      let value = parseFloat(input.value);
      if (isNaN(value) || value < 0) {
        input.value = '';
      }
      sum += Number(input.value) || 0;
    });

    U.id('FamOblittotalRatio').innerHTML = sum;
    if (sum === 100) {
      U.id('totalRatio').classList.remove('error');
      if (!noUpdt) {
        this.updateFamOblitHeirRatios();
      }
    } else {
      U.id('FamOblittotalRatio').classList.add('error');
    }
  },
  async addFamOblitHeir() {
    const inforObj = {
      relationship: [this.party1_name],
      organisation: 1,
    };
    this.party2_name && inforObj.relationship.push(this.party2_name);
    const data = await U.addCivillian(inforObj);
    if (data && data !== 'Canceled') {
      this.submitFamOblitHeir(data);
    }
  },
  async submitFamOblitHeir(data) {
    const vars = {};
    vars.application_uuid = this.application_uuid;
    if (data.user_uuid) {
      vars.heir_uuid = data.user_uuid;
    } else {
      vars.other = {
        name: data.orgName,
        contact: data.orgContact,
        notes: data.orgNotes,
      };
    }

    O.PREP([await O.mPOST('application/ins-obliteration-list', vars)]).then(async (res) => {
      const msg = res['application/ins-obliteration-list'].status;
      comp.toast(msg);

      const newHeirData = res['application/ins-obliteration-list'].data;
      this.buildFamOblitHeirListTable(newHeirData);
    });
  },
  async removeFamOblitHeir(heirID) {
    const method = 'application/rmv-obliteration-list';
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.application_heirs_uuid = heirID;

    O.PREP([await O.mPOST(method, vars)]).then(async (res) => {
      const newHeirData = res[method].data;
      const msg = res[method].status;
      comp.toast(msg);
      this.buildFamOblitHeirListTable(newHeirData);
    });
  },
  async updateFamOblitHeirRatios() {
    const ratios = document.querySelectorAll('.FamOblitHeirRatio');
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.heirs = [];

    ratios.forEach((radio) => {
      let dataObj = {};
      dataObj.heir_uuid = radio.id;
      dataObj.share_ratio = radio.value;
      vars.heirs.push(dataObj);
    });
    O.PREP([await O.mPOST('application/ups-obliteration-list', vars)])
      .then(async (res) => {
        const newHeirData = res['application/ups-obliteration-list'].data;
        const msg = res['application/ups-obliteration-list'].status;
        comp.toast(msg);
      })
      .catch(console.error);
  },

  //Save Page -------
  async savePage(nextPrev) {
    const method = 'application/val-app-page';
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.page_name = 'ADs_Heirs';
    O.PREP([await O.mPOST(method, vars)])
      .then(async (res) => {
        comp.toast(res[method].status);
        thsPage.pageProgress(res[method].data, nextPrev);
      })
      .catch(err => {console.log(err)});
  },
};
