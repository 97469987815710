import U from '../js/utils';
import svg from '../js/svg';

export default async (msg) => {
  const toastID = await U.uuidvMain();
  const tickElement = await svg.tickCircle();
  const tick = tickElement.outerHTML;

  const div = `
    <div id="${toastID}" class="toast">
      <div class="notification">
      <div>${tick}</div>
      <div>Success: ${msg}</div>
      </div>
    </div>
     `;

  const toast = U.createHTMLElementFromString(div);
  document.body.prepend(toast);

  setTimeout(() => {
    U.closeModal(toastID);
  }, 6000);
};

// return;

//  note(message) {
//   const un = this.uuidvMain();
//   const alertBanner = id("alertBanner");
//   const msg = message ? `- ${message}` : "";
//   const banner = this.divAtt("div", "class", "notification");
//   banner.setAttribute("id", `banner_${un}`);
//   const imgDiv = this.divAtt("div", "class", "");
//   const img = this.divAtt("div");
//   img.innerHTML = circleTickIcon;
//   const msgHdr = this.divAtt("div", "class", "");
//   msgHdr.innerHTML = `<p><strong>Success!</strong> ${msg}</p>`;
//   msgHdr.style.marginRight = "10px";
//   const btn = this.divAtt("div", "onclick", `hide('banner_${un}')`);
//   btn.innerHTML = "&#10005";
//   btn.style.color = "#747474";

//   imgDiv.appendChild(img);
//   banner.appendChild(imgDiv);
//   banner.appendChild(msgHdr);
//   banner.appendChild(btn);
//   alertBanner.appendChild(banner);
