import U from '../js/utils';
export default async (heading, msg, onclick) => {
  const div = `
    <div
      id="modal"
      class="fixed inset-0 z-50 overflow-y-auto bg-opacity-50 bg-black "
    >
      <div
        class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
      >
        <div
          class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
        >
          <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h4 id="modal-title">
                ${heading}
                </h4>
                <div class="mt-2">
                  <p id="modal-msg">
                  ${msg}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="bg-gray-50 px-4 py-3 flex md:justify-end justify-center sm:px-6"
          >
            <button class="btn-outline mr-3" onclick="U.closeModal('modal')">Cancel</button>
            <button id="modal-btn"  onclick="U.closeModal('modal') ${onclick ? `, ${onclick}` : ''}">Proceed</button>
          </div>
        </div>
      </div>
    </div>

     `;

  const modal = U.createHTMLElementFromString(div);
  document.body.prepend(modal);
};
