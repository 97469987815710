import U from '../js/utils';

export default (elID, ddData, valueDataName, textDataName, onchange, placeholder) => {
  let placeholderMsg = placeholder || '';
  const opts = U.buildOptsDD(ddData, valueDataName, textDataName, placeholderMsg);

  const div = `
    <div id="${elID}Div" class="input-group">
      <select id="${elID}" name="${elID}" ${onchange ? `onchange="${onchange}"` : ''} >
        ${opts}
      </select>
    </div>
  `;

  const element = U.createHTMLElementFromString(div);
  return element;
};
