const domain = location.hostname.split('.').filter((e) => e !== 'www');
const isLive = domain.includes('live') ? 1 : 0;

const themeOpts = {
  localhost: {
    primaryColor: '#1d9f86',
    primaryColorOpacity: '#1d9f8534',
    secondaryColor: '#B6BB34',
    secondaryColorOpacity: '#b6bb3444',
    secondaryColorLight: '#DEE0B9',
    thirdColor: '#215257',
    fourthColour: '#739092',
    darkColour: '#132530',
  },
};

export default {
  isLive: isLive,
  // displayLog: isLive ? 0 : 1,
  displayLog: 0,
  multiLangYN: 0,
  origin: location.origin,
  // apiURL: 'https://alphacluster.digitalfields.co.za/legatus/api/v1/',
  apiURL: process.env.API_URL, //'http://localhost:3111/legatus/api/v1/',
  imgPlaceholderURL: 'https://www.image.com/',
  clientThemes: themeOpts['localhost'],
};
