import comp from '../components/barrel';
import svg from '../js/svg';

export default {
  application_uuid: null,
  party1_uuid: null,
  party2_uuid: null,
  party1_cname: null,
  party2_cname: null,
  async prepare() {
    this.application_uuid = thsPage.vars.application_uuid;
    const vars = {};
    vars.application_uuid = this.application_uuid;
    this.recipe.methods.push(await O.mPOST('application/get-personal-detail', vars));
  },
  async render() {
    const rec = this.recipe.methods;
    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    this.loadPage(data);
  },
  async loadPage(data) {
    const res = data[`application/get-personal-detail`].data;
    this.party1_uuid = res.party1.user_uuid;
    this.party1_cname = `${res.party1.f_name} ${res.party1.s_name}`;
    this.party2_uuid = res.party2 ? res.party2.user_uuid : null;
    this.party2_cname = res.party2 ? `${res.party2.f_name} ${res.party2.s_name}` : null;
    Object.keys(communal).length === 0 && (await U.getCommunalDropdowns());
    // -------------------------------
    const p1Data = res.party1;
    this.buildPartyAccordion('p1', p1Data)
      .then((res) => {
        const form = U.id(`partyAccordions`);
        form.appendChild(res);
        // -------------------------------
        this.setDropdowns('p1', p1Data);
        U.id(`p1_arrow`).classList.add('arrowDown');
      })
      .catch((error) => console.error(error));

    const p2Data = res.party2;
    if (p2Data) {
      this.buildPartyAccordion('p2', p2Data)
        .then((res) => {
          const form = U.id(`partyAccordions`);
          form.appendChild(res);
          this.setDropdowns('p2', p2Data);
          U.id('p2') && U.hide(`p2_infoDiv`);
        })
        .catch((error) => console.error(error));
    }

    // -------------------------------
    // show missing field if not first time visiting page
    setTimeout(async () => {
      if (U.id('ADs_PersonalDetails').firstChild.classList.contains('incomplete')) {
        await U.validateForm('p1_infoDiv');
        U.id('p2') && U.validateForm('p2_infoDiv');
      }
    }, 2000);
  },
  async buildPartyAccordion(elID, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const card = U.divAtt('div', 'class', 'card mb-8');
        card.id = elID;
        // -------------------------------
        const header = U.divAtt('div', 'class', 'flex justify-between cursor-pointer');
        header.setAttribute('onclick', `thsSubTab.tglpersonalDetsAccordion(${elID})`);
        const heading = U.divAtt('h5');
        let accorArrow = await svg.chevronUp(`${elID}_arrow`);
        heading.innerHTML = `Personal Details - <span>${data.f_name} ${data.s_name}</span>`;
        header.appendChild(heading);
        header.appendChild(accorArrow);
        card.appendChild(header);
        // -------------------------------
        const infoDiv = U.divAtt('div');
        infoDiv.id = `${elID}_infoDiv`;
        const hr = U.divAtt('hr', 'class', 'mb-8');
        infoDiv.appendChild(hr);
        // -------------------------------
        const grid1 = U.divAtt('div', 'class', 'res-grid-cols-3');
        let titleDD = await comp.select(`${elID}_title`, communal.titles, 'title_uuid', 'short_text', 'Title', 1);
        grid1.appendChild(titleDD);
        let title_alt = await comp.inputText(`${elID}_alt_title`, data.alt_title ? data.alt_title : '', 'If other', 0);
        grid1.appendChild(title_alt);
        let initials = await comp.inputText(`${elID}_initials`, data.initials ? data.initials : '', 'Initials', 1);
        grid1.appendChild(initials);
        infoDiv.appendChild(grid1);
        // -------------------------------
        const grid2 = U.divAtt('div', 'class', 'res-grid-cols-2');
        let f_name = await comp.inputText(`${elID}_f_name`, data.f_name ? data.f_name : '', 'Full names', 1);
        let s_name = await comp.inputText(`${elID}_s_name`, data.s_name ? data.s_name : '', 'Surname', 1);
        let s_name_alt = await comp.inputText(`${elID}_s_name_alt`, data.prev_s_name ? data.prev_s_name : '', 'Previous surname', 0);
        let id_pass = await comp.inputIDPassport(`${elID}_id_pass`, data.id_nr ? 1 : 0, data.id_nr ? data.id_nr : data.passport_nr, 1);
        let genderDD = await comp.select(`${elID}_gender`, communal.genders, 'gender_uuid', 'long_text', 'Gender', 1);
        let langDD = await comp.select(`${elID}_lang`, communal.languages, 'language_uuid', 'long_text', 'Home language', 1);
        let email_address = await comp.inputText(`${elID}_email_address`, data.email_address ? data.email_address : '', 'Email address', 1);
        let email_address_alt = await comp.inputText(`${elID}_email_address_alt`, data.alt_email_address ? data.alt_email_address : '', 'Alt. email address', 0);
        grid2.appendChild(f_name);
        grid2.appendChild(s_name);
        grid2.appendChild(s_name_alt);
        grid2.appendChild(id_pass);
        grid2.appendChild(genderDD);
        grid2.appendChild(langDD);
        grid2.appendChild(email_address);
        grid2.appendChild(email_address_alt);
        infoDiv.appendChild(grid2);
        // -------------------------------
        const grid3 = U.divAtt('div', 'class', 'res-grid-cols-3');
        let cell_nr = await comp.inputText(`${elID}_cell_nr`, data.cell_nr || '', 'Cell number ', 1);
        let tell_nr = await comp.inputText(`${elID}_tell_nr`, data.tell_nr || '', 'Tell number ', 0);
        let fax_nr = await comp.inputText(`${elID}_fax_nr`, data.fax_nr || '', 'Fax number: ', 0);
        grid3.appendChild(cell_nr);
        grid3.appendChild(tell_nr);
        grid3.appendChild(fax_nr);
        infoDiv.appendChild(grid3);
        // -------------------------------
        // //physical address
        const grid4 = U.divAtt('div', 'class', 'res-grid-cols-1');
        const physHeading = U.divAtt('h6', 'class', 'mt-3');
        physHeading.innerHTML = 'Physical address';
        let phy_street = await comp.inputText(`${elID}_phy_street`, data.physical_address ? data.physical_address : '', 'Street address', 1);
        let phy_type = await comp.inputText(`${elID}_phy_type`, data.physical_address_type ? data.physical_address_type : '', 'Complex/Building (optional)', 0);
        grid4.appendChild(physHeading);
        grid4.appendChild(phy_street);
        grid4.appendChild(phy_type);
        infoDiv.appendChild(grid4);
        const grid5 = U.divAtt('div', 'class', 'res-grid-cols-2');
        let phy_suburb = await comp.inputText(`${elID}_phy_suburb`, data.physical_suburb ? data.physical_suburb : '', 'Suburb', 1);
        let phy_town = await comp.inputText(`${elID}_phy_town`, data.physical_town ? data.physical_town : '', 'Town/City', 1);
        let phy_provDD = await comp.select(`${elID}_phy_prov`, communal.provinces, 'province_uuid', 'long_text', 'Province', 1);
        let phy_code = await comp.inputText(`${elID}_phy_code`, data.physical_code ? data.physical_code : '', 'Area code', 1);
        grid5.appendChild(phy_suburb);
        grid5.appendChild(phy_town);
        grid5.appendChild(phy_provDD);
        grid5.appendChild(phy_code);
        infoDiv.appendChild(grid5);
        // -------------------------------
        // Postal Address
        const postHeading = U.divAtt('h6', 'class', 'mt-3');
        postHeading.innerHTML = 'Postal address';
        const grid6 = U.divAtt('div', 'class', 'res-grid-cols-1');
        const postalSame = await comp.inputCheckbox(`${elID}_postalSame`, 0, 'Postal Address is the same as Physical Address', `thsSubTab.postalSameTgl('${elID}')`);
        let pos_street = await comp.inputText(`${elID}_pos_street`, data.postal_address ? data.postal_address : '', 'Postal address', 1);
        grid6.appendChild(postHeading);
        grid6.appendChild(postalSame);
        grid6.appendChild(pos_street);
        infoDiv.appendChild(grid6);
        const grid7 = U.divAtt('div', 'class', 'res-grid-cols-2');
        let pos_suburb = await comp.inputText(`${elID}_pos_suburb`, data.postal_suburb ? data.postal_suburb : '', 'Suburb', 1);
        let pos_town = await comp.inputText(`${elID}_pos_town`, data.postal_town ? data.postal_town : '', 'Town/City ', 1);
        let pos_provDD = await comp.select(`${elID}_pos_provDD`, communal.provinces, 'province_uuid', 'long_text', 'Province', 1);
        let pos_code = await comp.inputText(`${elID}_pos_code`, data.postal_code ? data.postal_code : '', 'Area code', 1);
        grid7.appendChild(pos_suburb);
        grid7.appendChild(pos_town);
        grid7.appendChild(pos_provDD);
        grid7.appendChild(pos_code);
        infoDiv.appendChild(grid7);
        // -------------------------------
        card.appendChild(infoDiv);
       // console.log('3', card);
        // -------------------------------

        resolve(card);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  },
  setDropdowns(pID, pData) {
    //Set Dropdowns
    U.id(`${pID}_title`).value = pData.title_uuid ? pData.title_uuid : '';
    U.id(`${pID}_gender`).value = pData.gender ? pData.gender : '';
    U.id(`${pID}_lang`).value = pData.home_language ? pData.home_language : '';
    U.id(`${pID}_phy_prov`).value = pData.physical_province ? pData.physical_province : '';
    U.id(`${pID}_pos_provDD`).value = pData.postal_province ? pData.postal_province : '';
    if (U.id(`${pID}_pos_street`).value === U.id(`${pID}_phy_street`).value) {
      U.id(`${pID}_postalSame`).checked = true;
      this.postalSameTgl(`${pID}`);
    }
  },
  async tglpersonalDetsAccordion(elID) {
    let accordID = 'p1';
    let otheraccordID = 'p2';

    if (U.id('p2')) {
      // Testament Joint ---------
      if (elID !== 'p1') {
        accordID = 'p2';
        otheraccordID = 'p1';
      }

      // check if accordID id open or closed and do the opposite
      if (U.id(`${accordID}_infoDiv`).classList.contains('hide')) {
        U.unhide(`${accordID}_infoDiv`);
        U.id(`${accordID}_arrow`).classList.add('arrowDown');
        U.id(`${accordID}_infoDiv`).scrollTop = 0;
        // other div
        U.hide(`${otheraccordID}_infoDiv`);
        U.id(`${otheraccordID}_arrow`).classList.remove('arrowDown');
      } else {
        U.hide(`${accordID}_infoDiv`);
        U.id(`${accordID}_arrow`).classList.remove('arrowDown');
        // other div
        U.unhide(`${otheraccordID}_infoDiv`);
        U.id(`${otheraccordID}_infoDiv`).scrollTop = 0;
        U.id(`${otheraccordID}_arrow`).classList.add('arrowDown');
      }
    } else {
      // Testament Single ---------
      if (U.id(`${accordID}_infoDiv`).classList.contains('hide')) {
        U.unhide(`${accordID}_infoDiv`);
        U.id(`${accordID}_arrow`).classList.remove('arrowDown');
      } else {
        U.hide(`${accordID}_infoDiv`);
        U.id(`${accordID}_arrow`).classList.add('arrowDown');
      }
    }
  },
  postalSameTgl(divID) {
    U.id(`${divID}_pos_streetDiv`).classList.toggle('hide');
    U.id(`${divID}_pos_suburbDiv`).classList.toggle('hide');
    U.id(`${divID}_pos_townDiv`).classList.toggle('hide');
    U.id(`${divID}_pos_provDDDiv`).classList.toggle('hide');
    U.id(`${divID}_pos_codeDiv`).classList.toggle('hide');
  },
  async party1_continue() {
    return new Promise(async (resolve, reject) => {
      try {
        await U.validateForm('p1_infoDiv');
        const postalSame = U.id('p1_postalSame').checked === true ? true : false;

        const vars = {};
        vars.application_uuid = this.application_uuid;
        vars.party_uuid = this.party1_uuid;
        vars.title = U.id('p1_title').value || '';
        vars.alt_title = U.id('p1_alt_title').value;
        vars.initials = U.id('p1_initials').value;
        vars.f_name = U.id('p1_f_name').value;
        vars.s_name = U.id('p1_s_name').value;
        vars.prev_s_name = U.id('p1_s_name_alt').value;
        //@steph potential problem ID vs passnum ---
       // vars.id_nr = U.id('p1_id_pass').value; //p1_id_pass_toggle
        U.id('p1_id_pass_toggle').checked? vars.id_nr = U.id('p1_id_pass').value:vars.passport_nr = U.id('p1_id_pass').value;
        vars.gender = U.id('p1_gender').value || '';
        vars.home_language = U.id('p1_lang').value;
        vars.email_address = U.id('p1_email_address').value;
        vars.alt_email_address = U.id('p1_email_address_alt').value;
        vars.cell_nr = U.id('p1_cell_nr').value;
        vars.tell_nr = U.id('p1_tell_nr').value;
        vars.fax_nr = U.id('p1_fax_nr').value;
        vars.physical_address = U.id('p1_phy_street').value;
        vars.physical_address_type = U.id('p1_phy_type').value;
        vars.physical_suburb = U.id('p1_phy_suburb').value;
        vars.physical_town = U.id('p1_phy_town').value;
        vars.physical_province = U.id('p1_phy_prov').value || '';
        vars.physical_code = U.id('p1_phy_code').value;
        vars.postal_address = postalSame ? U.id('p1_phy_street').value : U.id('p1_pos_street').value;
        vars.postal_suburb = postalSame ? U.id('p1_phy_suburb').value : U.id('p1_pos_suburb').value;
        vars.postal_town = postalSame ? U.id('p1_phy_town').value : U.id('p1_pos_town').value;
        vars.postal_province = postalSame ? vars.physical_province : U.id('p1_pos_provDD').value || '';
        vars.postal_code = postalSame ? U.id('p1_phy_code').value : U.id('p1_pos_code').value;
        O.PREP([await O.mPOST('application/ups-personal-detail', vars)]).then(async (res) => {
          const msg = res['application/ups-personal-detail'].status;
          comp.toast(msg);
          resolve();
        });
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  },
  async party2_continue() {
    return new Promise(async (resolve, reject) => {
      try {
        await U.validateForm('p2_infoDiv');
        const vars = {};
        vars.application_uuid = this.application_uuid;
        vars.party_uuid = this.party2_uuid;
        vars.title = U.id('p2_title').value || '';
        vars.alt_title = U.id('p2_alt_title').value;
        vars.initials = U.id('p2_initials').value;
        vars.f_name = U.id('p2_f_name').value;
        vars.s_name = U.id('p2_s_name').value;
        vars.prev_s_name = U.id('p2_s_name_alt').value;
        //vars.id_nr = U.id('p2_id_pass').value;
        U.id('p2_id_pass_toggle').checked? vars.id_nr = U.id('p2_id_pass').value:vars.passport_nr = U.id('p2_id_pass').value;
        vars.gender = U.id('p2_gender').value || '';
        vars.home_language = U.id('p2_lang').value;
        vars.email_address = U.id('p2_email_address').value;
        vars.alt_email_address = U.id('p2_email_address_alt').value;
        vars.cell_nr = U.id('p2_cell_nr').value;
        vars.tell_nr = U.id('p2_tell_nr').value;
        vars.fax_nr = U.id('p2_fax_nr').value;
        vars.physical_address = U.id('p2_phy_street').value;
        vars.physical_address_type = U.id('p2_phy_type').value;
        vars.physical_suburb = U.id('p2_phy_suburb').value;
        vars.physical_town = U.id('p2_phy_town').value;
        vars.physical_province = U.id('p2_phy_prov').value || '';
        vars.physical_code = U.id('p2_phy_code').value;
        vars.postal_address = U.id('p2_pos_street').value;
        vars.postal_suburb = U.id('p2_pos_suburb').value;
        vars.postal_town = U.id('p2_pos_town').value;
        vars.postal_province = U.id('p2_pos_provDD').value || '';
        vars.postal_code = U.id('p2_pos_code').value;

        O.PREP([await O.mPOST('application/ups-personal-detail', vars)]).then(async (res) => {
          const msg = res['application/ups-personal-detail'].status;
          comp.toast(msg);
          resolve();
        });
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  },
  async savePage(nextPrev) {
    // if (!U.id(`p1_infoDiv`).classList.contains('hide')) {
    await this.party1_continue();
    // }
    if (U.id('p2')) {
      // !U.id(`p2_infoDiv`).classList.contains('hide') &&
      await this.party2_continue();
    }

    const method = 'application/val-app-page';
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.page_name = 'ADs_PersonalDetails';
    O.PREP([await O.mPOST(method, vars)])
      .then(async (res) => {
        comp.toast(res[method].status);
        thsPage.pageProgress(res[method].data, nextPrev);
      })
      .catch(err => {console.log(err)});
  },
};
