import comp from '../components/barrel';
import svg from '../js/svg';
export default {
  coExec: null,
  party: null,
  async prepare() {
    // this.recipe.methods.push(await O.mGET('communal/offices'));
  },
  async render() {
    const rec = this.recipe.methods;
    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage() {
    // Build Progress Steps
    let progressSteps = await comp.progressSteps(4);
    U.id('addAppCard').prepend(progressSteps);
    //Build Single/Joint Toggle
    let sinJointToggle = await comp.toggleLarge('singleJointToggle', 0, 'Single', 'Joint', 'thsPage.sigleJointToggle()');
    U.id('toggleDiv').appendChild(sinJointToggle);
    //   ---------------------------------------
    //Build Step 1
    let party1IDPass = await comp.inputIDPassport('party1IDPass', 1, '', 1);
    U.id('party1DivStep1').appendChild(party1IDPass);
    let party2IDPass = await comp.inputIDPassport('party2IDPass', 1, '', 0);
    U.id('party2DivStep1').appendChild(party2IDPass);
    //   ---------------------------------------
  },
  sigleJointToggle() {
    //Change column 1 spacinng
    const party1DivStep1 = U.id('party1DivStep1');
    const party1DivStep2 = U.id('party1DivStep2');
    party1DivStep1.classList.toggle('parties-single');
    party1DivStep2.classList.toggle('parties-single');

    //unhide column 2
    const party2DivStep1 = U.id('party2DivStep1');
    const party2DivStep2 = U.id('party2DivStep2');
    party2DivStep1.classList.toggle('hide');
    party2DivStep2.classList.toggle('hide');

    // Turn Off required validation
    const party2ID = U.id('party2IDPass');
    party2ID.required = !party2ID.required;
  },
  async step1Submit() {
    let isValid = await U.validateForm('partiesStep1');
    if (!isValid) {
      return;
    }

    U.loaderBtn(1, 'step1Btn');

    const vars = {};
    vars.testament = U.id('singleJointToggle').checked === true ? 'joint' : 'single';
    vars.id_pass1 = U.id('party1IDPass').value;
    vars.id_pass2 = U.id('party2IDPass').value;

    O.PREP([await O.mPOST('application/ver-new-app', vars)])
      .then(async (res) => {
        const verifyData = res['application/ver-new-app'].data;
        console.log('res', res);
        this.party = verifyData.personal;
        const will = verifyData.will;
        const user = verifyData.user;

        //   Build step 2 -----------
        this.buildStep2(this.party);
        U.hide('step1');
        U.unhide('step2');

        //   Build step 3 -----------
        if (will.will_nr) {
          const willNr = U.divAtt('h6');
          willNr.innerHTML = will.will_nr;
          U.id('willDiv').appendChild(willNr);
        }
        const optionsArrr = ['New will', 'Revision of existing will'];
        const willTypeRadio = await comp.inputRadio('will_type', optionsArrr, 1);
        U.id('willDiv').appendChild(willTypeRadio);
        let offshore = await comp.inputCheckbox('offshore_will', will.offshore_will, 'Offshore will');
        U.id('willDiv').appendChild(offshore);

        const coExecDiv = U.divAtt('div', 'class', 'mb-8');
        coExecDiv.id = 'coExecDiv';
        const cb2 = await comp.inputCheckbox('coExec', 0, 'Co-exec');
        coExecDiv.appendChild(cb2);
        U.id('willDiv').appendChild(coExecDiv);
        U.id(`coExec`).setAttribute('onchange', `thsPage.checkCos()`);

        //   Build step 4 -----------
        //   ------------------------
        if (user.org_admin_yn) {
          let advisor = await comp.select('advisor', user.advisors, 'user_uuid', 'c_name', 'Advisor', 1);
          advisor.classList.toggle('parties-single');
          U.id('advisorsStep4Div').appendChild(advisor);
        } else if (user.div_admin_yn) {
          this.getCompanyDD();
        } else if (user.advisor_yn) {
          // SKIP step 4
          // Build New Progress Steps
          let oldProgressBar = U.id('progressSteps');
          U.id('addAppCard').removeChild(oldProgressBar);
          let progressSteps = await comp.progressSteps(3);
          U.id('addAppCard').prepend(progressSteps);
          // change step 2 next
          U.id('step3Btn').innerHTML = 'Add Application';
          U.id('step3Btn').onclick = thsPage.addApplication();
        }

        U.updateProgressSteps(2);

        U.loaderBtn(0, 'step1Btn');
      })
      .catch(err => {console.log(err)});
  },
  async buildStep2(personal) {
    //PARTY 1
    if (personal.exi__id_pass1) {
      U.id('party1Name').innerHTML = `${personal.f_name1} ${personal.s_name1}`;
    } else {
      U.id('party1Name').innerHTML = `New (${U.id('party1IDPass').value})`;
      let party1name = await comp.inputText('p1_name', '', 'Name', 1);
      U.id('party1PersonalDets').appendChild(party1name);
      let party1surname = await comp.inputText('p1_s_name', '', 'Surname', 1);
      U.id('party1PersonalDets').appendChild(party1surname);
    }

    //PARTY 1
    if (personal.exi__id_pass2) {
      U.id('party2Name').innerHTML = `${personal.f_name2} ${personal.s_name2}`;
    } else if (!personal.exi__id_pass2 && U.id('singleJointToggle').checked === true) {
      U.id('party2Name').innerHTML = `New (${U.id('party2IDPass').value})`;
      let party2name = await comp.inputText('p2_name', '', 'Name', 1);
      U.id('party2PersonalDets').appendChild(party2name);
      let party2surname = await comp.inputText('p2_s_name', '', 'Surname', 1);
      U.id('party2PersonalDets').appendChild(party2surname);
    }
  },
  async step2Submit() {
    let isValid = await U.validateForm('partiesStep2');
    if (!isValid) {
      return;
    }

    //validate name and surname filled in if new
    U.hide('step2');
    U.unhide('step3');
    U.updateProgressSteps(3);
  },
  async step3Submit() {
    let isValid = await U.validateForm('willDiv');
    console.log('isValid', isValid);
    if (!isValid) {
      return;
    }

    U.hide('step3');
    U.unhide('step4');
    U.updateProgressSteps(4);
  },
  checkCos() {
    const cb = U.id(`coExec`);
    if (cb.checked !== true) {
      // remove trustee name
      if (U.id(`nameTagcoExecDiv`)) {
        U.id(`coExecDiv`).removeChild(U.id(`nameTagcoExecDiv`));
        U.id(`coExecDiv`).removeChild(U.id(`btncoExecDiv`));
      }
    } else {
      this.checkUser(coExecDiv);
    }
  },
  async buildNameTagAndBtn() {
    const nameTag = U.divAtt('div', 'class', 'nameTag mt-4');
    nameTag.id = `nameTagcoExecDiv`;

    const name = `${this.coExec.full_name}`;
    nameTag.innerHTML = `${name}`;

    const addHeir = U.divAtt('button', 'class', 'btn-icon btn-transparent');
    addHeir.setAttribute('onclick', `thsPage.checkCos()`);
    addHeir.id = `btncoExecDiv`;
    const btnText = U.divAtt('span');
    btnText.innerHTML = 'Change nomination';
    const repeatSVG = await svg.repeat();
    addHeir.appendChild(repeatSVG);
    addHeir.appendChild(btnText);

    U.id(`coExecDiv`).appendChild(nameTag);
    U.id(`coExecDiv`).appendChild(addHeir);
  },
  async checkUser() {
    let p1name;
    this.party.f_name1 ? (p1name = `${this.party.f_name1} ${this.party.s_name1}`) : (p1name = `${U.id('p1_name').value} ${U.id('p1_s_name').value}`);

    const inforObj = {
      relationship: [p1name],
      dob: 1,
      email_address: 0,
      cell_nr: 0,
      textarea: 'Notes',
    };
    if (U.id('singleJointToggle').checked === true) {
      let p2name;
      this.party.f_name2 ? (p2name = `${this.party.f_name2} ${this.party.s_name2}`) : (p2name = `${U.id('p2_name').value} ${U.id('p2_s_name').value}`);
      inforObj.relationship.push(`${p2name}`);
      // 9709120123456; existig example to test Bobber
    }
    const data = await U.addCivillian(inforObj);

    console.log('this is new user data', data);

    if (data) {
      // remove old exec
      if (U.id(`nameTagcoExecDiv`)) {
        U.id(`coExecDiv`).removeChild(U.id(`nameTagcoExecDiv`));
        U.id(`coExecDiv`).removeChild(U.id(`btncoExecDiv`));
      }
      Object.keys(communal).length === 0 && (await U.getCommunalDropdowns());
      this.coExec = data;
      this.buildNameTagAndBtn();
    } else {
      //cancelled adding new trustee
      U.id(`coExec`).checked = false;
    }
  },
  async getCompanyDD() {
    const vars = {};
    O.PREP([await O.mPOST('application/get-dd', vars)])
      .then(async (res) => {
        const companyDD = res['application/get-dd'].data.divisions;

        //Remove any previous Dropdowns
        const orgDiv = U.id('organisationDiv');
        const advisorDiv = U.id('advisorDiv');
        if (orgDiv) {
          await U.id('advisorsStep4Div').removeChild(orgDiv);
        }
        if (advisorDiv) {
          await U.id('advisorsStep4Div').removeChild(advisorDiv);
        }
        let company = await comp.select('company', companyDD, 'division_uuid', 'short_text', 'Company', 1, 'thsPage.getOrgansationsDD()');
        U.id('advisorsStep4Div').appendChild(company);
      })
      .catch(err => {console.log(err)});
  },
  async getOrgansationsDD() {
    //Remove any previous Dropdowns
    const orgDiv = U.id('organisationDiv');
    const advisorDiv = U.id('advisorDiv');
    if (orgDiv) {
      await U.id('advisorsStep4Div').removeChild(orgDiv);
    }
    if (advisorDiv) {
      await U.id('advisorsStep4Div').removeChild(advisorDiv);
    }

    const company = U.id('company').value;
    if (company) {
      const vars = {};
      vars.division_uuid = company;
      O.PREP([await O.mPOST('communal/organisations', vars)])
        .then(async (res) => {
          const orgDD = res['communal/organisations'].data.organisations;
          let advisor = await comp.select('organisation', orgDD, 'org_uuid', 'short_text', 'Organisation', 1, 'thsPage.getAdvisorsDD()');
          U.id('advisorsStep4Div').appendChild(advisor);
        })
        .catch(err => {console.log(err)});
    }
  },
  async getAdvisorsDD() {
    //Remove any previous Dropdowns
    const advisorDiv = U.id('advisorDiv');
    if (advisorDiv) {
      await U.id('advisorsStep4Div').removeChild(advisorDiv);
    }
    const organisation = U.id('organisation').value;
    if (organisation) {
      const vars = {};
      vars.org_uuid = organisation;
      O.PREP([await O.mPOST('communal/advisors', vars)])
        .then(async (res) => {
          const advisorDD = res['communal/advisors'].data.advisors;
          let advisor = await comp.select('advisor', advisorDD, 'advisor_uuid', 'c_name', 'Advisor', 1);
          U.id('advisorsStep4Div').appendChild(advisor);
        })
        .catch(err => {console.log(err)});
    }
  },
  async addApplication() {
    //VALIDATION HERE;

    let isValid = await U.validateForm('advisorsStep4Div');
    if (!isValid) {
      return;
    }

    const vars = {};
    vars.party1 = {
      id_pass: U.id('party1IDPass').value,
      f_name: U.id('p1_name') ? U.id('p1_name').value : '',
      s_name: U.id('p1_s_name') ? U.id('p1_s_name').value : '',
    };
    vars.party2 = {
      id_pass: U.id('singleJointToggle').checked === true ? U.id('party2IDPass').value : '',
      f_name: U.id('p2_name') ? U.id('p2_name').value : '',
      s_name: U.id('p2_s_name') ? U.id('p2_s_name').value : '',
    };

    vars.appType = document.querySelector('input[name="will_type"]:checked').value;
    vars.offshore_yn = U.id('offshore_will').checked === true ? 1 : 0;
    vars.advisor_uuid = U.id('advisor') ? U.id('advisor').value : '';

    vars.coexecutor = U.id('coExec').checked;
    if (vars.coexecutor) {
      vars.civillian_uuid = this.coExec.user_uuid;
      vars.notes = this.coExec.textarea;
    }

    O.PREP([await O.mPOST('application/ins-new-app', vars)])
      .then(async (res) => {
        console.log('user added', res);
        const msg = res['application/ins-new-app'].status;
        comp.toast(msg);

        O.PREP('ManageApplications');
        U.navSel('ManageApplications');
      })
      .catch(err => {console.log(err)});
  },
  cancelAppProgress() {
    const msg = 'Are you sure you would like to exit the add application process? <br> Progress will not be saved. Click Proceed.';
    const onclick = "O.PREP('ManageApplications')";
    comp.modal('Exit Add Application Process', msg, onclick);
  },
};
