import comp from '../components/barrel';
import svg from '../js/svg';
import U from '../js/utils';

export default {
  async prepare() {
    const vars = {};
    this.recipe.methods.push(await O.mPOST('application/app-list', vars));
    this.recipe.methods.push(await O.mPOST('communal/divisions', vars));
    this.recipe.methods.push(await O.mPOST('communal/organisations', vars));
    this.recipe.methods.push(await O.mPOST('communal/app_status', vars));
    this.recipe.methods.push(await O.mPOST('communal/testaments', vars));
  },
  async render() {
    const rec = this.recipe.methods;
    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    // Build Table Head
    this.buildTableHead(data);
    // Build Table Body
    const appListData = data[`application/app-list`].data;
    console.log('app-list', appListData);
    this.buildTableBody(appListData);
  },
  async buildTableHead(data) {
    const companyDDdata = await data[`communal/divisions`].data.divisions;
    const orgDDdata = await data[`communal/organisations`].data.organisations;
    const statusDDdata = await data[`communal/app_status`].data;
    const testamentsDDdata = data[`communal/testaments`].data;

    const appTableHead = U.id('appTableHead');
    appTableHead.innerHTML = '';
    const headRow = appTableHead.insertRow();

    const company = headRow.insertCell(0);
    let companyDD;
    companyDD = comp.selectThead('companyDD', companyDDdata, 'short_text', 'short_text', 'thsPage.getAppList()', 'Company');
    company.appendChild(companyDD);
    headRow.appendChild(company);

    const mainSource = headRow.insertCell(1);
    let mainSourceDD;
    mainSourceDD = comp.selectThead('mainSourceDD', orgDDdata, 'short_text', 'short_text', 'thsPage.getAppList()', 'Main Source');
    mainSource.appendChild(mainSourceDD);
    headRow.appendChild(mainSource);

    const advisor = headRow.insertCell(2);
    advisor.innerHTML = 'Advisor';

    const status = headRow.insertCell(3);
    let statusDD;
    statusDD = comp.selectThead('statusDD', statusDDdata, 'short_text', 'short_text', 'thsPage.getAppList()', 'Status');
    status.appendChild(statusDD);
    headRow.appendChild(status);

    const sourceRef = headRow.insertCell(4);
    sourceRef.innerHTML = 'Date Created';

    const appType = headRow.insertCell(5);
    appType.innerHTML = 'App Type';

    const testament = headRow.insertCell(6);
    let testamentDD;
    testamentDD = comp.selectThead('testamentDD', testamentsDDdata, 'short_text', 'short_text', 'thsPage.getAppList()', 'Single/Joint');
    testament.appendChild(testamentDD);
    headRow.appendChild(testament);

    const party1 = headRow.insertCell(7);
    party1.innerHTML = 'Party 1';

    const party2 = headRow.insertCell(8);
    party2.innerHTML = 'Party 2';

    //Action
    headRow.insertCell(9);
  },
  async buildTableBody(appListData) {
    const appListBody = U.id('appTableBody');
    appListBody.innerHTML = '';

    if (appListData.length) {
      appListData.forEach(async (row) => {
        const appRow = appListBody.insertRow();

        const company = appRow.insertCell(0);
        company.innerHTML = row.company;

        const mainSource = appRow.insertCell(1);
        mainSource.innerHTML = row.main_source;

        const advisor = appRow.insertCell(2);
        advisor.innerHTML = row.advisor;

        const status = appRow.insertCell(3);
        const tag = await comp.statusTag(row.status);
        status.appendChild(tag);

        const created_date = appRow.insertCell(4);
        created_date.innerHTML = await U.formatDate(row.created_date);

        const will_type = appRow.insertCell(5);
        will_type.innerHTML = row.app_type;

        const testament = appRow.insertCell(6);
        testament.innerHTML = row.testament;

        const party1_names = appRow.insertCell(7);
        party1_names.innerHTML = row.party1_names;

        const party2_names = appRow.insertCell(8);
        party2_names.innerHTML = row.party2_names;

        const editApp = appRow.insertCell(9);
        const editAppLink = `O.PREP('ViewApplication', 'application_uuid=${row.application_uuid}')`;
        const linkSVG = svg.linkNewPage(editAppLink);
        editApp.appendChild(linkSVG);
      });
    } else {
      //No Applications ---
      const appRow = appListBody.insertRow();
      const company = appRow.insertCell(0);
      company.innerHTML = 'No applications available.';
      company.colSpan = 10;
    }
  },
  async getAppList() {
    let timeout;
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      const filters = {};
      filters.testament = U.id('testamentDD').value;
      filters.search_filters = U.id('genSearch').value.trim();
      filters.company = U.id('companyDD').value;
      filters.main_source = U.id('mainSourceDD').value;
      filters.status = U.id('statusDD').value;


      const {value} = await O.getCookie('ewttgpvwwkf');
      
      const vars = {};
      vars.user_uuid = value;
      vars.filters = filters;

      O.PREP([await O.mPOST('application/app-list', vars)])
        .then(async (res) => {
          const appListData = res['application/app-list'].data;
          await this.buildTableBody(appListData);
        })
        .catch(console.error);
    }, 250);
  },
  async clearSearch() {
    const searchInps = document.querySelectorAll('.searchInputs');
    searchInps.forEach((inp) => {
      inp.value = '';
    });

    this.getAppList();
  },
};
